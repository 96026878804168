@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,700;1,400&display=swap");

.App {
  width: 100vw;
  height: 100vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.configurator {
  position: fixed;
  right: 24px;
  bottom: 25vh;
  width: 360px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.configurator__section__title {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: white;
}

.configurator__section__values {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
}

.item:hover {
  cursor: pointer;
  opacity: 0.9;
}

.item__dot {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 2px #999999;
}

.item.item--active .item__dot {
  border: solid 2px white;
}

.item__label {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: #aaa;
  text-transform: capitalize;
}
.item.item--active .item__label {
  color: white;
}



/* .App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
